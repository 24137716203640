






import { Component, Vue } from 'vue-property-decorator'
import FarmCameras from '@/components/FarmCameras.vue'

@Component({
  components: {
    FarmCameras
  }
})
export default class AdminFarmCameras extends Vue {}
