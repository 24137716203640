
























import { FarmCameraConverter } from '@/models/converters/FarmCameraConverter'
import { FarmCamera } from '@/models/FarmCamera'
import firebase from 'firebase/app'
import { Component, Prop, Vue } from 'vue-property-decorator'
import Camera from '@/components/Camera.vue'
import Tile from '@/components/Tile.vue'
import FarmSwitcher from '@/components/FarmSwitcher.vue'
import { mixins } from 'vue-class-component'
import UserMixin from '@/mixins/UserMixin.vue'
import { Farm } from '@/models/Farm'
import FarmMixin from '@/mixins/FarmMixin.vue'

@Component({
  components: {
    Camera,
    Tile,
    FarmSwitcher
  }
})
export default class AdminFarmCameras extends mixins(UserMixin, FarmMixin) {
  farm: Farm = null

  cameras: FarmCamera[] = []

  async created() {
    if (this.currentFarm) {
      this.farm = this.currentFarm
      await this.loadCameras()
    }
  }

  async farmChanged() {
    await this.loadCameras()
  }

  async loadCameras() {
    this.cameras = (
      await firebase
        .firestore()
        .collection('farms')
        .doc(this.farm.id)
        .collection('cameras')
        .where('active', '==', true)
        .withConverter(new FarmCameraConverter())
        .orderBy('index', 'asc')
        .get()
    ).docs.map((doc) => doc.data())
  }
}
